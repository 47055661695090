import { notDesktop, styled, Text } from '@obvio/app'
import { SvgArrow2 } from '@obvio/svg'
import { RichText } from '@obvio/template'
import { Grid, Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

import { useGalleryModal } from '@/components/Modals/GalleryModal'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const GalleryWrap = motion(styled.div`
  width: 100%;
  border-bottom: 1px solid white;
  position: relative;
  cursor: pointer;
`)

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

const MotionWrap = motion(Stack)

const HoverWrap = styled(motion.div)`
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 250%;
  color: white;
  height: 100%;
  > svg {
    transform: rotate(135deg);
    position: absolute;
    right: 0;
    top: 0;
  }
  > span {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media ${notDesktop} {
    display: none;
  }
`

type Gallery = {
  images: ImageAsset[]
}

type GalleriesTextProps = {
  title: string
  galleries: { title: string; data: Gallery }[]
  description: string
}

export function GalleriesText({
  title,
  galleries,
  description,
}: GalleriesTextProps): ReactElement {
  const { setValue } = useGalleryModal()

  return (
    <Wrap templateColumns="1fr 1fr" gap="extraLarge">
      <Stack kind="vertical">
        <Text>{title}</Text>
        <MotionWrap
          kind="vertical"
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          transition={{
            when: 'beforeChildren',
            staggerChildren: 0.3,
            delayChildren: 0.35,
          }}
        >
          {galleries.map(({ title: gallery, data: { images } }) => (
            <GalleryWrap
              key={gallery}
              onClick={() => setValue({ title: gallery, images })}
              variants={{
                initial: { opacity: 0, y: 10 },
                animate: { opacity: 1, y: 0 },
              }}
              transition={{
                duration: 0.75,
              }}
            >
              <motion.div whileHover="hover">
                <Text as="h2">{gallery}</Text>
                <HoverWrap
                  variants={{
                    hover: { opacity: 1 },
                  }}
                >
                  <SvgArrow2 />
                  <Text tag="span">GALERIA</Text>
                </HoverWrap>
              </motion.div>
            </GalleryWrap>
          ))}
        </MotionWrap>
      </Stack>
      <RichText value={description} />
    </Wrap>
  )
}
